
export default {
  methods: {
    openWhatsapp() {
      const url = `https://api.whatsapp.com/send?phone=${this.phone}&text=Quero%20contratar%20antecipa%C3%A7%C3%A3o%20do%20FGTS%20para%20o%20meu%20CPF%3A%20${this.cpf}`;
      window.open(url, "_blank");
    }
  },
  computed: {
    cpf () {
      return this.$store.state.whatsapp.cpf;
    },
    phone () {
      return this.$store.state.whatsapp.phone;
    },
    loading () {
      return this.$store.state.whatsapp.loading;
    }
  },
};
